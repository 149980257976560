export const list  = [
    {
        title: 'Stake your $AOT',
        subtitle:'Reward $USDC.',
      icon:{
        stakeCoin:'assets/images/icon/icon_aot.png',
        rewardCoin:'assets/images/icon/icon_usdt.png'
      },
      apy:{
        label:'APY',
        value:'10%',
      },
      totalStake:{
        label:'Total Stake',
        value:'100 USDC'
      },
      totalStaker:{
        label:'Total Staker',
        value:'410'
      },
      contract:'0xeF7bB4a2F19d5EF4eb29e3A125090E10Df428C7E',
	  address:'0x658B7f700B2Fd96c9C3F1747DCFcfFC3C7b10e39',
      link: '/staking/0xeF7bB4a2F19d5EF4eb29e3A125090E10Df428C7E',
      status:'participate'
    },
  ];
  