
import AbiToken from "../abi/AbiToken.json";
import AbiStakingPool from "../abi/AbiStakingPool.json";
export const walletProvider = {
    METAMASK: 'metaMask',
    TRUSTWALLET: 'trustWallet',
    SAFEPAL: 'safePal',
    WALLET_CONNECT: 'walletConnect'
};

//TESTNET
export const RPC_NODE_TESTNET={
    http:"https://polygon.llamarpc.com",
    wss:"wss://polygon.gateway.tenderly.co",
}

export const RPC_NODE={
    http:"https://polygon.llamarpc.com",
    wss:"wss://polygon.gateway.tenderly.co"
}

export const TOKEN_STAKE ={
    address:'0x658B7f700B2Fd96c9C3F1747DCFcfFC3C7b10e39',
    abi: AbiToken
}

export const POOL_STAKE ={
    address:'0xeF7bB4a2F19d5EF4eb29e3A125090E10Df428C7E',
    abi: AbiStakingPool
}